import React from 'react';
import UserNameContext from '../../contexts/UserNameContext';
import Title from '../dashboard/Title';
import { Link } from 'react-router-dom';
import * as locales from '@mui/material/locale';
import { ThemeProvider } from '@emotion/react';
import { DataGrid, esES } from '@mui/x-data-grid';
import { createTheme } from '@mui/material';

const theme = createTheme({}, esES);

function makeDetailLink(name, id) {
  return (<Link to={`${id}`}>{`${name}`}</Link>);
}

function createHeaderItem( field, headerName, width, flex, renderCell, type ) {

  const item = {
    field,
    headerName, 
    width,
    flex,
    renderCell,
    type: type ?? 'string',
  }
  return item;
}

const InstructorCoursesDataGrid = ({headers, rows}) => {

  const onRowClick = (event) => {
    console.log(event);
  }

  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales['esES']),
    [locales, theme],
  );

  return (
    <ThemeProvider theme={themeWithLocale}>
      <DataGrid
        onRowClick={onRowClick}
        rows={rows}
        disableRowSelectionOnClick={false}
        columns={headers.map((headerItem, _) => headerItem)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 8 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection={false}
      />
    </ThemeProvider>
  );
};

export default function InstructorCoursesTable({
  title,
  rows,
}) {

  const value = React.useContext(UserNameContext);

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <InstructorCoursesDataGrid headers={
          [
            createHeaderItem('name', 'Nombre del curso', 200, 1,  (params) => {
              return makeDetailLink(params.row.course.name, params.row.id);
            }), 
            createHeaderItem('dateStart', 'Fecha Inicial', 200, .5, (params) => {
                return params.row.dateStart.toLocaleDateString(
                    'es-ES', {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  );
            }),
            createHeaderItem('dateEnd', 'Fecha Final', 200, .5, (params) => {
                return params.row.dateEnd.toLocaleDateString(
                    'es-ES', {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  );
            }),
          ]
        } 
        rows={rows}/>
    </React.Fragment>
  );
}
