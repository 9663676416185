import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import './App.css';
import AppRoutes from './routes/AppRoutes';
import UserNameContext from './contexts/UserNameContext';

function App() {
  const [value, setValue] = useState('');
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <UserNameContext.Provider value={value}>
        <AppRoutes />
      </UserNameContext.Provider>
    </LocalizationProvider>
  );
}

export default App;
