import config from "../config/config"
import constants from "../utils/constants";
import axios from "axios";

async function callService(url, method = 'GET', payload = null) {
    const jwtToken = window.localStorage.getItem(constants.tokenKey);
    const respose = await fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`,
            'api': config.apiKey,
        },
        body: payload !== null ? JSON.stringify(payload) : null,
    })
    .then((response) => {
        if(response.status == 200 || response.status == 201) {
            return response.json();
        }
        return null
    });

    return respose;
}

async function getBase64(url) {
    const jwtToken = window.localStorage.getItem(constants.tokenKey);
    const axiosConfig = {
        headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'api': config.apiKey,
        },
        responseType: 'blob'
    };
    return axios
      .get(url, axiosConfig)
      .then(response => response.data)
      .catch(error => null);
  }

async function uploadFile(url, signatureFile) {
    const jwtToken = window.localStorage.getItem(constants.tokenKey);
    const axiosBody = {
        file: signatureFile,
    };

    const axiosConfig = {
        headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'api': config.apiKey,
        }
    };

    return axios.postForm(
        url,
        axiosBody,
        axiosConfig,
    )
}

export { callService, uploadFile, getBase64 };