import { useState } from "react";

import LogService from "../../services/LogService";

function createData(log) {
    console.log('log.name', log);
    return { 
        id: log.id, 
        action: log.action, 
        name: log.user.name, 
        newValue: JSON.stringify(log.newValue),
        createdAt: new Date(log.createdAt) 
    };
}

const useLogs = () => {
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getLogs = async () => {
        const response =  await LogService.getLogs();
        const users = response.map((log) => createData(log));
        setRows(users);
        setIsLoading(false);
    }
    
    return {
        rows,
        getLogs,
        isLoading,
    }
};

export { useLogs };