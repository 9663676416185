import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import UsersTable from './UsersTable';
import { useUsers } from './Users.services';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Logo from '../logo/logo';

const theme = createTheme();

function Users() {

    const { rows, getUsers } = useUsers();

    useEffect(() => {
        getUsers()
    }, []);

    return (
        <>
            {
                rows.length == 0 ?
                    <Logo />
                    :
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >

                        <Toolbar />
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <AppBar
                                position="absolute"
                                color="default"
                                elevation={0}
                                sx={{
                                    position: 'relative',
                                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                                }}
                            >
                                <Toolbar>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Link to="new" style={{ textDecoration: 'none' }}>
                                                <Button variant="contained">
                                                    Agregar nuevo
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Toolbar>
                            </AppBar>
                            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    {/* Recent Orders */}
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <UsersTable
                                                title={'Usuarios'}
                                                headers={['Nombre', 'Email', 'Rol', 'Estado']}
                                                rows={rows}
                                            />
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Container>
                        </ThemeProvider>
                    </Box>
            }
        </>
    )
}

export default Users;