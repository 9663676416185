import React from 'react';
import UserNameContext from '../../contexts/UserNameContext';
import Title from '../dashboard/Title';
import { Link } from 'react-router-dom';
import * as locales from '@mui/material/locale';
import { ThemeProvider } from '@emotion/react';
import { DataGrid, esES } from '@mui/x-data-grid';
import { Typography, createTheme } from '@mui/material';

const theme = createTheme({}, esES);

function makeDetailLink(name, id) {
  return (<Link to={`${id}`}>{`${name}`}</Link>);
}

function createHeaderItem( field, headerName, width, flex, renderCell, type ) {

  const item = {
    field,
    headerName, 
    width,
    flex,
    renderCell,
    type: type ?? 'string',
  }
  return item;
}

const StudentCoursesDataGrid = ({headers, rows}) => {

  const onRowClick = (event) => {
    console.log(event);
  }

  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales['esES']),
    [locales, theme],
  );

  return (
    <ThemeProvider theme={themeWithLocale}>
      <DataGrid
        onRowClick={onRowClick}
        rows={rows}
        disableRowSelectionOnClick={false}
        columns={headers.map((headerItem, _) => headerItem)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 8 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection={false}
      />
    </ThemeProvider>
  );
};

export default function StudentCoursesTable({
  title,
  rows,
}) {

  // const value = React.useContext(UserNameContext);
  const tableOrText = rows.length > 0 ? <StudentCoursesDataGrid headers={
    [
      createHeaderItem('name', 'Nombre del curso', '40%', 1, (params) => {
        return makeDetailLink(params.row.course.name, params.row.id);
      }), 
      createHeaderItem('dateStart', 'Fecha Inicial', '100%', .5, (params) => {
          return params.row.dateStart.toLocaleDateString(
              'es-ES', {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            );
      }),
      createHeaderItem('dateEnd', 'Fecha Final', '100%', .5, (params) => {
          return params.row.dateEnd.toLocaleDateString(
              'es-ES', {
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            );
      }),
    ]
  } 
  rows={rows}/> : <Typography variant="body2">No ha tomado ninguna capacitación</Typography>;
  return (
    <React.Fragment>
      <Title>{title}</Title>
      { tableOrText }
    </React.Fragment>
  );
}
