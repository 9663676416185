import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const options = ['Administrador', 'Operador'];

export default function NewUserForm1({ formControl }) {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            value={formControl.formDataState.type}
            onChange={(event, newValue) => {
              formControl.setType(newValue);
            }}
            inputValue={formControl.formDataState.type}
            onInputChange={(event, newInputValue) => {
              formControl.setType(newInputValue)
            }}
            id="controllable-states-demo"
            options={options}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Tipo de usuario" />}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
