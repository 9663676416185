import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useUserDetail } from "./UserDetail.services";
import { Button, Divider } from '@mui/material';
import { useParams } from 'react-router';
import { useEffect } from 'react';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UserDetail = () => {

  const { id } = useParams();

  const {
    userDetails,
    updateName,
    updateEmail,
    updateType,
    updateUserStatus,
    options,
    getUser,
    originalDataChanged,
    updateUser,
    updateSuccess,
    updateFailure,
    handleClick,
    handleClose,
    // region password change
    passwordState,
    setFirstPassword,
    setSecondPassword,
    updatePassword,
    // region password validation
    passwordNotMatch,
  } = useUserDetail(id);

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" gutterBottom>
                Datos generales
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Nombre"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    value={userDetails.name}
                    onChange={(event) => {
                      updateName(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} >
                  <TextField
                    required
                    id="email"
                    name="email"
                    label="Correo electrónico"
                    fullWidth
                    autoComplete="shipping address-line1"
                    variant="standard"
                    value={userDetails.email}
                    onChange={(event) => {
                      updateEmail(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    value={userDetails.type}
                    onChange={(event, newValue) => {
                      updateType(newValue);
                    }}
                    inputValue={userDetails.type}
                    onInputChange={(event, newInputValue) => {
                      updateType(newInputValue)
                    }}
                    id="controllable-states-demo"
                    options={options}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Tipo de usuario" />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox 
                      checked={userDetails.isActive}
                      onChange={(event) => {
                        updateUserStatus(event.target.checked);
                      }} 
                      color="secondary" 
                      name="saveAddress" 
                      value="yes" />
                    }
                    label="Usuario activo"
                  />
                </Grid> 
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Divider />
                  <Button
                    disabled={!originalDataChanged}
                    variant="contained"
                    sx={{ mt: 3, ml: 1 }}
                    onClick={() => { updateUser() }}
                  >
                    Actualizar
                  </Button>
                </Grid>
              </Grid>

              <Typography variant="h6" gutterBottom>
                Cambiar Contraseña
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    id="outlined-password-input"
                    name="password"
                    label="Contraseña"
                    type="password"
                    fullWidth
                    autoComplete="given-name"
                    variant="filled"
                    value={passwordState.password}
                    onChange={(event) => {
                      setFirstPassword(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    id="outlined-password-input"
                    name="confirm"
                    label="Confirmar contraseña"
                    type="password"
                    fullWidth
                    autoComplete="given-name"
                    variant="filled"
                    error={passwordNotMatch}
                    helperText={passwordNotMatch ? 'Las contraseñas no coinciden': ''}
                    value={passwordState.password2}
                    onChange={(event) => {
                      setSecondPassword(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    sx={{ mt: 3, ml: 1 }}
                    onClick={() => {
                      updatePassword();
                    }}
                  >
                    Cambiar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={updateSuccess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Usuario actualizado
        </Alert>
      </Snackbar>
      <Snackbar open={updateFailure} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Error, no se pudo actualizar al usuario
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserDetail;