import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Button, Divider } from '@mui/material';
import { useParams } from 'react-router';
import { useEffect } from 'react';

import useDeputies from "./Deputies.services";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const imageStyle = {
  backgroundColor: 'white',
  maxWidth: 500,
};

const DeputyDetails = () => {

  const { id } = useParams();

  const {
    getDeputyDetails,
    originalDataChanged,
    deputy,
    deputyDetail,
    handleClose,
    handleClick,
    updateSuccess,
    updateFailure,
    updateName,
    updateLastname,
    updatePosition,
    updateDeputy,
    handleOnChangeSignature,
    imageUrl,
  } = useDeputies();

  useEffect(() => {
    getDeputyDetails(id);
  }, []);

  // region upload image
  const showNoSignatureBox = () => {
    return <>
      <Typography variant="body1" gutterBottom>
        Aún no se ha especificado ninguna firma para este representante.
      </Typography>
      <label htmlFor="upload-photo">
        <Button sx={{ mt: 2, }} color="primary" variant="contained" component="span">
          Agregar firma
        </Button>
        <input
          id="upload-photo"
          hidden
          accept="image/*"
          name="upload-photo"
          type="file"
          onChange={handleOnChangeSignature} />
      </label>
    </>
  }

  const showSignatureBox = () => {
    return <>
      <img style={imageStyle} src={imageUrl} />
      <label htmlFor="update-photo">
        <input
          hidden
          id="update-photo"
          accept="image/*"
          name="update-photo"
          type="file"
          onChange={handleOnChangeSignature} />
        <Button sx={{ mt: 2, }} color="secondary" variant="contained" component="span">
          Actualizar firma
        </Button>
      </label>
    </>
  }
  // end region

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" gutterBottom>
                Datos generales
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>ID: {deputyDetail.id}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Nombre"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    value={deputyDetail.name}
                    onChange={(event) => {
                      updateName(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="lastname"
                    name="lastname"
                    label="Apellido"
                    fullWidth
                    autoComplete="given-lastname"
                    variant="standard"
                    value={deputyDetail.lastname}
                    onChange={(event) => {
                      updateLastname(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="registroSTPS"
                    name="registroSTPS"
                    label="Registro STPS"
                    fullWidth
                    autoComplete="given-lastname"
                    variant="standard"
                    value={deputyDetail.position ?? ''}
                    onChange={(event) => {
                      updatePosition(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{
                    p: 2,
                    border: '1px dashed gray',
                    backgroundColor: '#f5f5f5',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                  }}>
                    {imageUrl ? showSignatureBox() : showNoSignatureBox()}
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Divider />
                  <Button
                    disabled={!originalDataChanged}
                    variant="contained"
                    sx={{ mt: 3, ml: 1 }}
                    onClick={() => { updateDeputy() }}
                  >
                    Actualizar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={updateSuccess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Instructor actualizado
        </Alert>
      </Snackbar>
      <Snackbar open={updateFailure} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Error, no se pudo actualizar los datos del instructor
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DeputyDetails;