import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Checkbox, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

const toItemList = (student) => {
    if (!student) return '';
    return `${student.id} - ${student.completeName}`;
}

const setCompaniesField = (companies, byCompany, setFilterByCompany) => {
    let companyValue = null;
    if (byCompany != -1 &&  companies) {
        let company = companies.filter((c) => c.id == byCompany);
        if(company.length > 0) {
            companyValue = company[0].name;
        }
    }

    return (
        <Autocomplete
            value={companyValue ?? ""}
            id="controllable-states-demo"
            options={companies?.map(c => c.name) ?? []}
            onInputChange={(event, newInputValue) => {
                let company = companies.filter(company => company.name == newInputValue)
                if (company.length > 0) {
                  setFilterByCompany(company[0]?.id ?? null);
                } 
              }}
            renderInput={(params) => {
                return (<TextField {...params} label="Compañía" />)
            }}
        />
    );
};
const fullList = (students, byCompany, selectedStudents, checkStudent) => {
    if (byCompany != -1) {
        students = students.filter((s) => s.company?.id == byCompany);
    }
    return (
        <List dense sx={{
            overflow: 'auto',
            maxHeight: 400,
        }}>
            {students.map((student) => {
                const labelId = `checkbox-list-secondary-label-${student.id}`;
                return (
                    <ListItem
                        key={student.id}
                        secondaryAction={
                            <Checkbox
                                edge="end"
                                onChange={(e) => {
                                    e.preventDefault();
                                    checkStudent(student.id, e.target.checked);
                                 }}
                                checked={selectedStudents.includes(student.id)}
                                inputProps={{ 'aria-labelledby': labelId }}
                            />
                        }
                    >
                        <ListItemButton>
                            <ListItemText id={labelId} primary={`${student.name} ${student.lastname}`} />
                        </ListItemButton>
                    </ListItem>
                )
            })}
        </List>
    );
};

const selectedFullList = (students, removeStudents) => {
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>{'Participantes'}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {students.map((row) => (
                    <TableRow key={row.id}>
                        <TableCell>
                            {row.completeName}
                        </TableCell>
                        <TableCell>
                            <IconButton
                                aria-label="delete"
                                onClick={() => {
                                    removeStudents(row);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

const TrainingNewForm5 = ({ 
    students, 
    removeStudents, 
    formTrainingDataState, 
    companies,
    byCompany,
    setFilterByCompany,
    selectedStudents,
    checkStudent,
    addStudentBulk,
    removeStudentBulk,
 }) => {

    const [student, setStudent] = useState(null);

    return (<React.Fragment>
        <Grid container spacing={12}>
            <Grid item xs={12}>
                {setCompaniesField(companies, byCompany, setFilterByCompany)}
            </Grid>
            <Grid item xs={12}>
                {fullList(students, byCompany, selectedStudents, checkStudent)}
            </Grid>
            <Grid item xs={12}>
                <Button
                    disabled={selectedStudents.length == 0}
                    variant="contained"
                    sx={{ mt: 3, ml: 1 }}
                    onClick={(e) => {
                        e.preventDefault();
                        addStudentBulk();
                    }}
                >
                    Añadir Participantes
                </Button>
            </Grid>
            <Grid item xs={8}>
                {selectedFullList(formTrainingDataState.students, removeStudents)}
                <Button
                    disabled={formTrainingDataState.students.length == 0}
                    variant="contained"
                    sx={{ mt: 3, ml: 1 }}
                    onClick={(e) => {
                        e.preventDefault();
                        removeStudentBulk();
                    }}
                >
                    Eliminar participantes
                </Button>
            </Grid>
        </Grid>
    </React.Fragment>);
};

export default TrainingNewForm5;