import { useState } from 'react';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { DnDContainer } from '../draggableList/DnDContainer';

const SyllabusEditor = ({ 
  syllabus, 
  addSyllabusElement, 
  removeSyllabusElement, 
  updateSyllabus, 
  onEditItem,
  onEditSubitem,
  onRemoveSubitem,
  onAddSubitem,
}) => {
  const [newSyllabusElement, setNewSyllabusElement] = useState(null)
  return <>
    <Typography variant="h6" gutterBottom>Temario</Typography>
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TextField
          variant="standard"
          value={newSyllabusElement ?? ''}
          onChange={(e) => {
            setNewSyllabusElement(e.target.value);
          }}
        />
        <Button
          onClick={() => {
            /* Add syllabus element */
            if (newSyllabusElement != null) {
              addSyllabusElement(newSyllabusElement);
              setNewSyllabusElement(null); // reset the field
            }
          }}
        >
          Agregar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <DnDContainer
          initial={syllabus}
          onUpdateList={(list) => {
            updateSyllabus(list)
          }} 
          onRemove={(card) => {
            removeSyllabusElement(card.id);
          }}
          onEdit={(card) => {
            onEditItem(card);
          }}
          onEditSubitem={onEditSubitem}
          onRemoveSubitem={onRemoveSubitem}
          onAddSubitem={onAddSubitem}
          />
      </Grid>
    </Grid>

  </>
}

export default SyllabusEditor;