import * as React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { CssBaseline, AppBar, Button } from '@mui/material';

import InstructorsTable from './InstructorsTable';
import useInstructors from './Instructors.services';
import Logo from '../logo/logo';

export default function Instructors() {

  const { instructors, getInstructors, isLoading } = useInstructors();

  React.useEffect(() => {
    getInstructors();
  }, []);
  return (
    <>
      {
        isLoading
          ? <Logo />
          : <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <CssBaseline />
            <AppBar
              position="absolute"
              color="default"
              elevation={0}
              sx={{
                position: 'relative',
                borderBottom: (t) => `1px solid ${t.palette.divider}`,
              }}
            >
              <Toolbar>
                <Grid container spacing={1}>
                  <Grid item>
                    <Link to="new" style={{ textDecoration: 'none' }}>
                      <Button variant="contained">
                        Agregar nuevo
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                {/* Recent Orders */}
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <InstructorsTable
                      title={'Instructores'}
                      headers={['Nombre', 'Apellido', 'Clave STPS']}
                      rows={instructors}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
      }
    </>
  );
}