import React from 'react';
import UserNameContext from '../../contexts/UserNameContext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../dashboard/Title';
import { Link } from 'react-router-dom';

function makeDetailLink(ctaText, id) {
  return (<Link to={`${id}`}>{ctaText}</Link>);
}
export default function UsersTable({
  title,
  headers,
  rows,
}) {

  const value = React.useContext(UserNameContext);

  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            { headers.map((headerItem, index) => <TableCell key={index}>{headerItem}</TableCell>) }
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                {makeDetailLink(row.name, row.id)}
              </TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.rol}</TableCell>
              <TableCell>{row.isActive ? 'Activo' : 'Inactivo'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
