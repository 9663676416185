import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

const TrainingNewForm3 = ({ instructors, setInstructor, formTrainingDataState }) => {
    return instructors.length > 0 && (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={8}>
                    <Autocomplete
                        defaultValue={''}
                        value={formTrainingDataState?.instructor?.completeName ?? ''}
                        onInputChange={(event, newInputValue) => {
                            let instructor = instructors.filter(instructor => instructor.completeName == newInputValue)
                            if (instructor.length > 0) {
                                setInstructor(instructor[0])
                            }
                        }}
                        id="controllable-states-demo"
                        options={instructors.map((i) => { return { ...i, key: i.id } })}
                        renderInput={(params) => <TextField {...params} label="Instructor" />}
                        getOptionLabel={(option) => option.completeName ?? option}
                        renderOption={(props, option, state) => {
                            return <ListItemText
                                {...props}
                                key={option.id}
                                primary={option.completeName}
                                secondary={option.conflicted && 
                                    <React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            style={{ display: 'inline', color: 'red',  marginLeft: 6 }}
                                        >
                                            {` - conflicto`}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />;
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default TrainingNewForm3;