import update from 'immutability-helper'
import { useCallback, useEffect, useState } from 'react'
import { SubCard } from './SubCard.js';

const style = {
  width: 400,
  marginLeft: '30px',
  backgroundColor: 'red',
}

const initialState = [];

export const DnDSubContainer = ({ 
  initial, 
  itemId,
  onUpdateList, 
  onEditSubitem,
  onRemoveSubitem,
}) => {
  {
    const [cards, setCards] = useState(initialState);

    useEffect(() => {
      if (initialState != initial) {
        setCards(initial)
      }
    }, [initial]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setCards((prevCards) => {
        let upt = update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        });
        // region notify
        let newIndex = 0;
        let updated = upt.map((item) => {
          return { ...item, id: newIndex++ };
        })
        onUpdateList(updated);
        // end region
        return upt
      }
      )
    }, [])

    const renderSubCard = (card, index) => {
      return ( card 
        ? <SubCard
          key={card?.id ?? -1}
          index={index}
          id={card?.id ?? -1}
          text={card?.name ?? 'failed'}
          moveCard={moveCard}
          onRemove={() => {
            // onRemove(card);
            onRemoveSubitem(itemId, card.id);
          }}
          onEdit={() => {
            // onEdit(card);
            onEditSubitem(itemId, card);
          }}
        />
        : <></>
      )
    }
    return (
      <>
        <div style={style}>{cards.map((card, i) => {
            return renderSubCard(card, i)
          })}</div>
      </>
    )
  }
}
