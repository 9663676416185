import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { Button, Divider } from '@mui/material';
import useDeputies from './Deputies.services';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeputyNew = () => {

  const {
    deputyDetail,
    updateSuccess,
    updateFailure,
    handleClick,
    handleClose,
    originalDataChanged,
    updateName,
    updateLastname,
    updatePosition,
    createDeputy,
  } = useDeputies();

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" gutterBottom>
                Datos generales
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="name"
                    name="name"
                    label="Nombre"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                    value={deputyDetail.name}
                    onChange={(event) => {
                      updateName(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="lastname"
                    name="lastname"
                    label="Apellido"
                    fullWidth
                    autoComplete="given-lastname"
                    variant="standard"
                    value={deputyDetail.lastname}
                    onChange={(event) => {
                      updateLastname(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="Position"
                    name="position"
                    label="Posición"
                    fullWidth
                    autoComplete="given-lastname"
                    variant="standard"
                    value={deputyDetail.position ?? ''}
                    onChange={(event) => {
                      updatePosition(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Divider />
                  <Button
                    disabled={!originalDataChanged ?? ""}
                    variant="contained"
                    sx={{ mt: 3, ml: 1 }}
                    onClick={() => { createDeputy() }}
                  >
                    Crear
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={updateSuccess} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Participante creado
        </Alert>
      </Snackbar>
      <Snackbar open={updateFailure} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Error, no se pudo crear al Participante
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DeputyNew;