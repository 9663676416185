import { useEffect, useReducer, useState } from "react";
import InstructorService from "../../services/InstructorService";

var initialData = {
    id: "",
    name: "",
    lastname: "",
    instructorSTPS: null,
    spptr: null,
    signature: null,
    trainings: [],
};

function responseToInstructorDetail(response) {
    return {
        id: response.id,
        name: response.name,
        lastname: response.lastname,
        instructorSTPS: response.instructorSTPS,
        spptr: response.spptr,
        signature: response.signature,
        trainings: createTraining(response.trainings),
    }
}

function createTraining(trainings) {
    const finalTraining = trainings.map((training) => {
        const newTraining = {
            ...training,
            dateStart: new Date(training.dateStart),
            dateEnd: new Date(training.dateEnd),
        };
        return newTraining;
    });
    return finalTraining;
}

const ACTION_INITIAL = "ACTION_INITIAL";
const ACTION_UPDATE_NAME = "ACTION_UPDATE_NAME"
const ACTION_UPDATE_LASTNAME = "ACTION_UPDATE_LASTNAME"
const ACTION_UPDATE_INSTRUCTOR_STPS = "ACTION_UPDATE_INSTRUCTOR_STPS"
const ACTION_UPDATE_INSTRUCTOR_SPPTR = "ACTION_UPDATE_INSTRUCTOR_SPPTR"
const ACTION_UPDATE_SIGNATURE = "ACTION_UPDATE_SIGNATURE"

const reducer = (state, payload) => {
    switch (payload.action) {
        case ACTION_INITIAL:
            initialData = payload.data
            return payload.data;
        case ACTION_UPDATE_NAME:
            return {
                ...state,
                name: payload.data,
            }
        case ACTION_UPDATE_LASTNAME:
            return {
                ...state,
                lastname: payload.data,
            }
        case ACTION_UPDATE_INSTRUCTOR_STPS:
            return {
                ...state,
                instructorSTPS: payload.data,
            }
        case ACTION_UPDATE_INSTRUCTOR_SPPTR:
            return {
                ...state,
                spptr: payload.data,
            }
        case ACTION_UPDATE_SIGNATURE:
            return {
                ...state,
                signature: payload.data,
            }
        default:
            return state;
    }
}

const useInstructorDetail = (studentId) => {

    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateFailure, setUpdateFailure] = useState(false);
    const [originalDataChanged, setOriginalDataChanged] = useState(false);

    const [instructorDetails, dispatch] = useReducer(reducer, initialData);

    const getInstructor = async () => {
        const response = await InstructorService.getInstructor(studentId);
        if (response) {
            setInitialData(responseToInstructorDetail(response));
        }
    };

    useEffect(() => {
        originalDataHasChanged();
        getSignature();
    }, [instructorDetails]);

    const originalDataHasChanged = () => {
        const hasChanged = instructorDetails.name !== initialData.name
            || instructorDetails.lastname !== initialData.lastname
            || instructorDetails.instructorSTPS !== initialData.instructorSTPS
            || instructorDetails.spptr !== initialData.spptr
            || instructorDetails.signature !== initialData.signature

        setOriginalDataChanged(hasChanged);
    };

    const setInitialData = (data) => {
        dispatch({ action: ACTION_INITIAL, data: data });
    }

    const updateName = (name) => {
        dispatch({ action: ACTION_UPDATE_NAME, data: name });
    }

    const updateLastname = (lastname) => {
        dispatch({ action: ACTION_UPDATE_LASTNAME, data: lastname });
    }

    const updateInstructorSTPS = (instructorSTPS) => {
        dispatch({ action: ACTION_UPDATE_INSTRUCTOR_STPS, data: instructorSTPS });
    };

    const updateSPPTR = (spptr) => {
        dispatch({ action: ACTION_UPDATE_INSTRUCTOR_SPPTR, data: spptr });
    };

    const updateInstructorSignature = (signature) => {
        dispatch({ action: ACTION_UPDATE_SIGNATURE, data: signature });
    };

    const handleClick = () => {
        setUpdateSuccess(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setUpdateSuccess(false);
        setUpdateFailure(false);
    };

    const updateInstructor = async () => {
        const response = await InstructorService.updateInstructor(instructorDetails.id, instructorDetails);
        if (response) {
            setInitialData(responseToInstructorDetail(response));
            setUpdateSuccess(true);
        } else {
            setUpdateFailure(true);
        }
    };

    const updateSignature = async (signatureFile) => {
        const response = await InstructorService.updateInstructorSignature(instructorDetails.id, signatureFile);
        if (response) {
            updateInstructorSignature(signatureFile.name);
        } else {
            setUpdateFailure(true);
        }
    };

    const getSignature = async () => {
        if (instructorDetails.id) {
            const response = await InstructorService.getInstructorSignature(instructorDetails.id);
            if (response) {
                var reader = new window.FileReader();
                reader.readAsDataURL(response);
                reader.onload = function () {

                    setImageUrl(reader.result)
                }
            } else {
                setImageUrl(null)
            }
        } else {
            setImageUrl(null)
        }
    };

    const [imageUrl, setImageUrl] = useState(null);

    const handleOnChangeSignature = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            updateSignature(file);
            setImageUrl(reader.result);
        };

        reader.readAsDataURL(file);
    }

    return {
        instructorDetails,
        getInstructor,
        updateSuccess,
        updateFailure,
        handleClick,
        handleClose,
        originalDataChanged,
        updateName,
        updateLastname,
        updateInstructor,
        updateInstructorSTPS,
        updateSPPTR,
        updateSignature,
        handleOnChangeSignature,
        imageUrl,
        setImageUrl,
        getSignature,
    }
};

export { useInstructorDetail };