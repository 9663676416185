import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DrawIcon from '@mui/icons-material/Draw';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import BadgeIcon from '@mui/icons-material/Badge';
import ApprovalIcon from '@mui/icons-material/Approval';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { Link } from 'react-router-dom';

export const mainListItems = () => (
  <React.Fragment>
    <Link to="/admin" style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
    </Link>
    <Link to="/admin/courses" style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton>
        <ListItemIcon>
          <ApprovalIcon />
        </ListItemIcon>
        <ListItemText primary="1. Cursos" />
      </ListItemButton>
    </Link>
    <Link to="/admin/companies" style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton>
        <ListItemIcon>
          <CorporateFareIcon />
        </ListItemIcon>
        <ListItemText primary="2. Compañías" />
      </ListItemButton>
    </Link>
    <Link to="/admin/students" style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="3. Participantes" />
      </ListItemButton>
    </Link>
    <Link to="/admin/instructors" style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="4. Instructores" />
      </ListItemButton>
    </Link>
    <Link to="/admin/deputies" style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton>
        <ListItemIcon>
          <DrawIcon />
        </ListItemIcon>
        <ListItemText primary="5. Representantes" />
      </ListItemButton>
    </Link>
    <Link to="/admin/trainings" style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton>
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText primary="6. Capacitaciones" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);

export const secondaryListItems = ({
  logoutAction,
}) => (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Administración
    </ListSubheader>
    <Link to="/admin/users" style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton>
        <ListItemIcon>
          <BadgeIcon />
        </ListItemIcon>
        <ListItemText primary="Usuarios" />
      </ListItemButton>
    </Link>
    <Link to="/admin/logs" style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton>
        <ListItemIcon>
          <SummarizeIcon />
        </ListItemIcon>
        <ListItemText primary="Logs" />
      </ListItemButton>
    </Link>
    <ListItemButton onClick={ () => logoutAction() }>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Salir" />
    </ListItemButton>
  </React.Fragment>
);
