import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function NewUserForm3({ formControl }) {

  const handleChange = (event) => {
    formControl.setIsActive(event.target.checked);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Resumen
      </Typography>
      <List disablePadding>
        <ListItem key={`${formControl.formDataState.name} ${formControl.formDataState.lastname}`} sx={{ py: 1, px: 0 }}>
          <ListItemText primary={`${formControl.formDataState.name} ${formControl.formDataState.lastname}`} secondary={formControl.formDataState.email} />
          <Typography variant="body2">{formControl.formDataState.type}</Typography>
        </ListItem>
      </List>
      <Grid container spacing={6} sx={{ marginTop: '20px' }}>
        <Grid item xs={10} sm={10}>
          <FormControlLabel
            control={<Checkbox
              checked={formControl.formDataState.isActive} 
              onChange={handleChange}
              color="secondary" 
              name="saveAddress" 
              value="yes" />}
            label={<Typography variant="body2">Activar usuario</Typography>}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
