import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import NewUserForm1 from './NewUserForm1';
import NewUserForm2 from './NewUserForm2';
import NewUserForm3 from './NewUserForm3';
import { useUserNew } from './UserNew.services';

const theme = createTheme();

function getStepContent(step, formDataState, setType, setName, setLastname, setEmail, createUser, setIsActive) {
    switch (step) {
        case 0:
            return <NewUserForm1 formControl={{ formDataState, setType }} />;
        case 1:
            return <NewUserForm2 formControl={{ formDataState, setName, setLastname, setEmail }} />;
        case 2:
            return <NewUserForm3 formControl={{ formDataState, createUser, setIsActive }} />;
        default:
            throw new Error('Unknown step');
    }
}

const UserNew = () => {
    const {
        // region form data
        formDataState,
        setType,
        setName,
        setLastname,
        setEmail,
        setIsActive,
        // region form flow
        activeStep,
        handleNext,
        handleBack,
        createUser,
        steps,
        reset,
    } = useUserNew();

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        <Typography component="h1" variant="h4" align="center">
                            Nuevo usuario
                        </Typography>
                        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                    Usuario creado exitosamente.
                                </Typography>
                                <Typography variant="subtitle1">
                                    La confirmación ha sido enviada al correo electrónico del nuevo usuario.
                                </Typography>
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 3, ml: 1 }}
                                    onClick={() => reset()}
                                >
                                    Añadir otro usuario
                                </Button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {getStepContent(activeStep, formDataState, setType, setName, setLastname, setEmail, createUser, setIsActive)}
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                            Atrás
                                        </Button>
                                    )}

                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 3, ml: 1 }}
                                    >
                                        {activeStep === steps.length - 1 ? 'Crear' : 'Siguiente'}
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )}
                    </Paper>
                </Container>
            </ThemeProvider>
        </Box>
    );
}

export default UserNew;