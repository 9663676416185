import { useState } from "react";
import StudentService from "../../services/StudentService";

function createData(id, name, lastname, curp, rfc) {
    return { id, name, lastname, curp, rfc, completeName: `${name} ${lastname}` };
}

const useStudents = () => {
    const [students, setStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getStudents = async () => {
        const response =  await StudentService.getStudents();
        const students = response.map((student) => createData(student.id, student.name, student.lastname, student.curp, student.rfc));
        setStudents(students);
        setIsLoading(false);
    }

    return {
        students,
        getStudents,
        isLoading,
    }
};

export default useStudents