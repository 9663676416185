import { useState } from "react";
import InstructorService from "../../services/InstructorService";

function createData(response) {
    return { 
        id: response.id, 
        name: response.name, 
        lastname: response.lastname, 
        instructorSTPS: response.instructorSTPS,
        completeName: `${response.name} ${response.lastname}`,
    };
}

const useInstructors = () => {
    const [instructors, setInstructors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getInstructors = async () => {
        const response =  await InstructorService.getInstructors();
        const instructors = response.map((instructor) => createData(instructor));
        setInstructors(instructors);
        setIsLoading(false);
    }

    return {
        instructors,
        getInstructors,
        isLoading,
    }
};

export default useInstructors;