import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TrainingsTable from './TrainingsTable';
import useTrainings, { FlowType } from './Trainings.services';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '../logo/logo';

export default function Trainings() {

  const { trainings, getTrainings, isLoading } = useTrainings(FlowType.list);

  React.useEffect(() => {
    getTrainings();
  }, []);

  return (
    <>
    {
      isLoading
      ? <Logo />
      : <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Grid container spacing={1}>
            <Grid item>
              <Link to="new" style={{ textDecoration: 'none' }}>
                <Button variant="contained">
                  Agregar nuevo
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <TrainingsTable
                title={'Capacitaciones'}
                rows={trainings}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
    }
    </>
  );
}