import React, { useState } from 'react';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const TrainingNewForm2 = ({ setDateStart, setDateEnd, formTrainingDataState }) => {
    
    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={4}>
                    <DatePicker
                        label="Inicia"
                        value={dayjs(formTrainingDataState.dateStart)}
                        slotProps={{
                            textField: {
                                helperText: 'MM / DD / AAAA',
                            },
                        }}
                        onChange={(newValue) => setDateStart(newValue)} />
                </Grid>
                <Grid item xs={4}>
                    <DatePicker
                        label="Termina"
                        value={dayjs(formTrainingDataState.dateEnd)}
                        slotProps={{
                            textField: {
                                helperText: 'MM / DD / AAAA',
                            },
                        }}
                        onChange={(newValue) => setDateEnd(newValue)} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default TrainingNewForm2;
