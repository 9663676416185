import { useState, useEffect } from 'react';
import LoginService from '../../services/LoginService';

const useLogin = () => {
    const [logged, setLogged] = useState(false);
    const [noAuth, setNoAuth] = useState(null);

    useEffect(() => {
        if (LoginService.isLoggedIn()) {
            setLogged(true);
        } else {
            setLogged(false);
        }
    }, [logged]);

    const signIn = async (email, password) => { 
        const result = await LoginService.signIn(email, password);
        if (result) {
            setLogged(result);
        } else {
            setNoAuth(result);
        }
    }

    const reset = () => {
        setNoAuth(null)
    }

    return {
        logged,
        noAuth,
        signIn,
        reset,
    };
}

export { useLogin };