import { useEffect, useState } from "react";
import CompanyService from "../../services/CompanyService";

let companyDetailInitial = {
    id: "",
    name: "",
}

const companyNewDetailInitial = {
    id: "",
    name: "",
}

function createData(response) {

    if (response == null) return null;

    return {
        id: response.id,
        name: response.name,
    };
}

const useCompanies = () => {
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [companyDetail, setCompanyDetail] = useState(companyDetailInitial);
    const [newCompanyDetail, setNewCompanyDetail] = useState(companyNewDetailInitial);
    const [originalDataChanged, setOriginalDataChanged] = useState(false);
    const [originalNewDataChanged, setOriginalNewDataChanged] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateFailure, setUpdateFailure] = useState(false);
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        checkDataChanged();
    }, [companyDetail])

    useEffect(() => {
        checkNewDataChanged();
    }, [newCompanyDetail])

    // region state updates

    const updateName = (name) => {
        const newData = {
            ...companyDetail,
            name: name,
        }
        setCompanyDetail(newData);
    };

    const updateNewName = (name) => {
        const newData = {
            ...companyDetail,
            name: name,
        }
        setNewCompanyDetail(newData);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateSuccess(false);
    };

    const checkDataChanged = () => {
        const hasChanged = JSON.stringify(companyDetailInitial) != JSON.stringify(companyDetail);
        setOriginalDataChanged(hasChanged)
    }

    const checkNewDataChanged = () => {
        const hasChanged = JSON.stringify(companyNewDetailInitial) != JSON.stringify(newCompanyDetail);
        setOriginalNewDataChanged(hasChanged)
    }

    // region service calls

    const getCompanies = async () => {
        const response = await CompanyService.getCompanies();
        const companies = response.map((company) => createData(company));
        setLoading(false);
        setCompanies(companies);
    };

    const getCompany = async (id) => {
        const response = await CompanyService.getCompany(id);
        const company = createData(response);
        companyDetailInitial = JSON.parse(JSON.stringify(company));
        setCompanyDetail(company);
    };

    // end region

    const updateCompany = async () => {
        const response = await CompanyService.updateCompany(
            companyDetail.id,
            {
                ...companyDetail,
            }
        );
        if (response) {
            setUpdateSuccess(true);
            const company = createData(response);
            companyDetailInitial = { ...company }
            setCompanyDetail(company);
        } else {
            setUpdateFailure(true);
        }
    };

    const createCompany = async () => {
        const response = await CompanyService.createCompany(newCompanyDetail);
        if (response) {
            setUpdateSuccess(true);
            setCompanyDetail(companyDetailInitial);
            setRedirect(`/admin/companies/${response.id}`);
        } else {
            setUpdateFailure(true);
        }
    };

    return {
        loading,
        companies,
        companyDetail,
        getCompanies,
        getCompany,
        updateName,
        updateNewName,
        updateSuccess,
        updateFailure,
        originalDataChanged,
        handleClose,
        updateCompany,
        createCompany,
        redirect,
        originalNewDataChanged,
        newCompanyDetail,
    }
};

export default useCompanies;