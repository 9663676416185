import JSZip from "jszip";

const useDownload = () => {

    /**
     * Array of PDF blob files containing each certificate
     */
    async function handleZip(filename, pdfs) {
        const zip = new JSZip();
    
        // Add pdf to the zip file
        for (let i = 0; i < pdfs.length; i++) {
            console.log('pdfs[i]',pdfs[i].filename);
          zip.file(`${pdfs[i].filename}.pdf`, pdfs[i].data);
        }
    
        // Generate the zip file
        const zipData = await zip.generateAsync({
          type: "blob",
          streamFiles: true,
        });
    
        // Create a download link for the zip file
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(zipData);
        link.target = '_blank';
        link.download = `${filename}.zip`;
        link.click();
      }
    
      return { handleZip };
}

export default useDownload;
