import config from "../config/config"
import { callService, uploadFile, getBase64 } from "./CommonService";

const APIUrlBase = config.apiHost + config.apiBase + 'instructors';

/* Retrieve list of all of guest in the system */
async function getInstructors(dateStart, dateEnd) {
    let url = APIUrlBase;
    if (dateStart, dateEnd) {
        url += `?dateStart=${dateStart?.toISOString().split('T')[0]}&dateEnd=${dateEnd?.toISOString().split('T')[0]}`;
    }
    return await callService(url);
}

/* Retrieve one single user data */
async function getInstructor(instructorId) {
    const url = APIUrlBase + '/' + instructorId;
    return await callService(url);
}

/* Call API service to create a user */
async function createInstructor(data) {

    const url = APIUrlBase;
    const method = "POST";
    const payload = {
        name: data.name,
        lastname: data.lastname,
        instructorSTPS: data.instructorSTPS,
        spptr: data.spptr,
    };

    return await callService(url, method, payload);
}

/* Call API service to create a user */
async function updateInstructor(instructorId, data) {

    const url = APIUrlBase + '/' + instructorId;
    const method = 'PATCH';
    const payload = {
        name: data.name,
        lastname: data.lastname,
        instructorSTPS: data.instructorSTPS !== '' ? data.instructorSTPS : null,
        spptr: data.spptr !== '' ? data.spptr : null,
    };

    return await callService(url, method, payload);
}

async function updateInstructorSignature(instructorId, signatureFile) {
    return await uploadFile(APIUrlBase + '/' + instructorId + '/upload-signature', signatureFile)
}

async function getInstructorSignature(instructorId) {
    return await getBase64(APIUrlBase + '/' + instructorId + '/signature');
}

export default { 
    getInstructors, 
    getInstructor, 
    createInstructor, 
    updateInstructor, 
    updateInstructorSignature,
    getInstructorSignature,
 };