import config from "../config/config"
import { callService } from "./CommonService";

const APIUrlBase = config.apiHost + config.apiBase + 'trainings';

/* Retrieve list of all of guest in the system */
async function getTrainings() {
    const url = APIUrlBase;
    return await callService(url);
}

/* Retrieve one single user data */
async function getTraining(trainingId) {
    const url = APIUrlBase + '/' + trainingId;
    return await callService(url);
}

/* Call API service to create a user */
async function updateTraining(trainingId, data) {
    const url = APIUrlBase + '/' + trainingId;
    const method = 'PATCH';
    const payload = {
        dateStart: data.dateStart?.toISOString().split('T')[0],
        dateEnd: data.dateEnd?.toISOString().split('T')[0],
        code: data.code,
        spptrCode: data.spptrCode,
        instructorId: data.instructor.id,
        deputyId: data.deputy.id,
        courseId: data.course.id,
        validity: data.validity,
        totalHours: data.totalHours,
    };
    return await callService(url, method, payload);
}

/* Call API service to create a user */
async function deleteTraining(trainingId) {
    const url = APIUrlBase + '/' + trainingId;
    const method = 'PATCH';
    const payload = {
        deleted: true,
    };
    return await callService(url, method, payload);
}

/* Call API service to create a user */
async function createTraining(data) {
    const url = APIUrlBase;
    const method = 'POST';
    const payload = {
        dateStart: data.dateStart.toISOString().split('T')[0],
        dateEnd: data.dateEnd.toISOString().split('T')[0],
        code: data.code,
        spptrCode: data.spptrCode == "" ? null : data.spptrCode,
        instructorId: data.instructor.id,
        courseId: data.course.id,
    }
    return await callService(url, method, payload);
}

/* Call API service to create a user */
async function addStudentToTraining(trainingId, studentId) {
    const url = APIUrlBase + '/add-student';
    const method = 'POST';
    const payload = {
        trainingId: trainingId,
        studentId: studentId,
    }
    return await callService(url, method, payload);
}

/* Call API service to create a user */
async function removeStudentToTraining(trainingId, studentId) {
    const url = APIUrlBase + '/remove-student';
    const method = 'DELETE';
    const payload = {
        trainingId: trainingId,
        studentId: studentId,
    }
    return await callService(url, method, payload);
}

/* Call API service to create a user */
async function generateFolio(date) {
    const url = APIUrlBase + `/folio?dateStart=${date}`;
    const method = 'GET';    
    return await callService(url, method);
}

export default { getTrainings, getTraining, updateTraining, createTraining, addStudentToTraining, removeStudentToTraining, generateFolio, deleteTraining };