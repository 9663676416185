import { useEffect, useRef, useState } from "react";
import CourseService from "../../services/CourseService";

const courseDetailDefault = {
    id: "",
    name: "",
    sptr: null,
    syllabus: [],
}

let courseDetailInitial = {
    id: "",
    name: "",
    sptr: null,
    syllabus: [],
}

function createData(response) {

    if (response == null) return null;

    return {
        id: response.id,
        name: response.name,
        sptr: response.sptr,
        syllabus: syllabusResponseToArray(response.syllabus) ?? [],
    };
}

function syllabusResponseToArray(syllabusResponse) {
    try {
        let temp = syllabusResponse ?? [];
        
        if (temp.length > 0) {
            for(let i = 0; i < temp.length; i++){
                temp[i].id = i;
                const subitems = temp[i].subItem
                if(subitems) {
                    for(let j = 0; j < subitems.length; j++){
                        subitems[j].id = j;
                    }
                    temp[i].subItem = subitems;
                }
            }
        }
        return temp;
    } catch {
        return [];
    }
}

function syllabusArrayToResponse(syllabus) {
    const preparedSyllabus = syllabus.map((item) => {
        delete item.id;
        return {
            ...item,
            subItem: item.subItem?.map((subItem) => {
                return {name: subItem.name}
            }) ?? [],
        }
    });
    return preparedSyllabus;
}

const useCourses = (flowType) => {
    const [courses, setCourses] = useState([]);
    const [courseDetail, setCourseDetail] = useState(courseDetailInitial);
    const [originalDataChanged, setOriginalDataChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateFailure, setUpdateFailure] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [deleteOpen, setDeleteOpen] = useState(false);

    useEffect(() => {
        if (flowType == 1) {
            setCourseDetail(courseDetailDefault);
        }
    }, [flowType])

    useEffect(() => {
        checkDataChanged();
    }, [courseDetail])

    // region state updates

    const updateName = (name) => {
        const newData = {
            ...courseDetail,
            name: name,
        }
        setCourseDetail(newData);
    };

    const updateSPTR = (sptr) => {
        const newData = {
            ...courseDetail,
            sptr: sptr,
        }
        setCourseDetail(newData);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateSuccess(false);
        setUpdateFailure(false);
    };

    const checkDataChanged = () => {
        const hasChanged = 
            JSON.stringify(courseDetailInitial) != JSON.stringify(courseDetail)
            || JSON.stringify(courseDetailInitial.syllabus) != JSON.stringify(courseDetail.syllabus);
        setOriginalDataChanged(hasChanged)
    }

    // region service calls

    const getCourses = async () => {
        const response = await CourseService.getCourses();
        const courses = response.map((course) => createData(course));
        console.log('courses', courses);
        setIsLoading(false);
        setCourses(courses);
    };

    const getCourse = async (id) => {
        const response = await CourseService.getCourse(id);
        const course = createData(response);
        courseDetailInitial = JSON.parse(JSON.stringify(course));
        setCourseDetail(course);
    };

    // region update syllabus items

    const addSyllabusElement = (name) => {
        const idArray = courseDetail.syllabus.map((e) => e.id);
        const newId = idArray.length > 0 ? Math.max(...idArray) + 1 : 1;
        const newSyllabus = [...courseDetail.syllabus];
        newSyllabus.push({ id: newId, name });
        const newSyllabusData = {
            ...courseDetail,
            syllabus: newSyllabus,
        };
        setCourseDetail(newSyllabusData);
    };

    const updateSyllabus = (list) => {
        courseDetail.syllabus = list;
        setCourseDetail({
            ...courseDetail,
        });
    };

    const removeSyllabusElement = (elementId) => {
        let newSyllabus = courseDetail.syllabus.filter((item) => item.id != elementId)
        let newSyllabusData = {
            ...courseDetail,
            syllabus: newSyllabus,
        };
        setCourseDetail(newSyllabusData);
    };

    const onEditSubitem = (itemId, subItemId, name) => {
        console.log('onEditSubitem');
    };

    const handleClickDeleteOpen = () => {
        setDeleteOpen(true);
    };
    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const onRemoveCourse = async () => {
        const response = await CourseService.deleteCourse(courseDetail.id);
        if (response) {
            setUpdateSuccess(true);
            setRedirect('/admin/courses');
        } else {
            setUpdateFailure(true);
        }
    }

    const onRemoveSubitem = (itemId, subItemId) => {
        let subitems = [];
        for(let i = 0; i < courseDetail.syllabus.length; i++) {
            const item = courseDetail.syllabus[i];
            if (item.id != itemId) {
                continue;
            }
            for(let j = 0; j < item.subItem.length; j++) {
                if (item.subItem[j].id == subItemId) {
                    continue;
                }
                subitems.push(item.subItem[j]);
            }
            courseDetail.syllabus[i].subItem = subitems;
        }   

        const newData = JSON.parse(JSON.stringify(courseDetail))
        setCourseDetail({
            ...newData,
        });
    };

    const onAddSubitem = (itemId) => {
        const items = courseDetail.syllabus;
        for(let i = 0; i < items.length; i++) {
            if(items[i].id == itemId) {
                if (!items[i].subItem) items[i].subItem = [];
                const idArray = items[i].subItem.map((e) => e.id);
                const newId = idArray.length > 0 ? Math.max(...idArray) + 1 : 1;
                items[i].subItem.push({ id: newId, name: '<new SubItem>' });
            }
        }
        setCourseDetail({
            ...courseDetail,
        });
    };

    // end region

    const updateCourse = async () => {
        const response = await CourseService.updateCourse(
            courseDetail.id,
            {
                ...courseDetail,
                syllabus: syllabusArrayToResponse(courseDetail.syllabus),
            }
        );
        if (response) {
            setUpdateSuccess(true);
            const course = createData(response);
            courseDetailInitial = { ...course }
            setCourseDetail(course);
        } else {
            setUpdateFailure(true);
        }
    };

    const createCourse = async () => {
        const response = await CourseService.createCourse({
            ...courseDetail,
            syllabus: syllabusArrayToResponse(courseDetail.syllabus),
        });
        if (response) {
            setUpdateSuccess(true);
            setCourseDetail(courseDetailInitial);
            setRedirect(`/admin/courses/${response.id}`);
        } else {
            setUpdateFailure(true);
        }
    };

    return {
        courses,
        courseDetail,
        getCourses,
        getCourse,
        updateName,
        updateSPTR,
        updateSuccess,
        updateFailure,
        originalDataChanged,
        handleClose,
        updateCourse,
        createCourse,
        addSyllabusElement,
        removeSyllabusElement,
        updateSyllabus,
        onEditSubitem,
        onRemoveSubitem,
        onAddSubitem,
        redirect,
        isLoading,
        onRemoveCourse,
        handleClickDeleteOpen,
        handleDeleteClose,
        deleteOpen,
    }
};

export default useCourses;