import React from 'react';
import UserNameContext from '../../contexts/UserNameContext';
import Title from '../dashboard/Title';
import * as locales from '@mui/material/locale';
import { ThemeProvider, Typography, createTheme } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';

const theme = createTheme({}, esES);

function createHeaderItem( field, headerName, width, renderCell, type ) {

  const item = {
    field,
    editable: true,
    disabled: true,
    headerName, 
    width,
    renderCell,
    type: type ?? 'string',
  }
  return item;
}

const LogsDataGrid = ({headers, rows}) => {

  const onRowClick = (event) => {
    console.log(event);
  }

  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales['esES']),
    [locales, theme],
  );

  return (
    <ThemeProvider theme={themeWithLocale}>
      <DataGrid
        onRowClick={onRowClick}
        rows={rows}
        disableRowSelectionOnClick={false}
        columns={headers.map((headerItem, _) => headerItem)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 8 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection={false}
      />
    </ThemeProvider>
  );
};

export default function LogsTable({
  title,
  rows,
}) {

  const value = React.useContext(UserNameContext);
  
  return (
    <React.Fragment>
      <Title>{title}</Title>
      {
        rows.length == 0
        ? <Typography>No hay registro de actividad en el sistema aún</Typography>
        : <LogsDataGrid headers={
            [
              createHeaderItem('id', 'ID', 50), 
              createHeaderItem('action', 'Acción', 270), 
              createHeaderItem('name', 'Usuario', 270), 
              createHeaderItem('newValue', 'Cambios', 270), 
              createHeaderItem('createdAt', 'Fecha', 170, (params) => {
                return params.row.createdAt.toLocaleDateString(
                    'es-ES', {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  );
            }), 
            ]
          } 
          rows={rows}/>
      }
    </React.Fragment>
  );
}
