import update from 'immutability-helper'
import { useCallback, useEffect, useState } from 'react'
import { Card } from './Card.js'
import { DnDSubContainer } from './DnDSubContainer.js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";

const style = {
  width: 400,
}

const initialState = [];

export const DnDContainer = ({ 
  initial, 
  onUpdateList, 
  onRemove, 
  onEdit,
  onEditSubitem,
  onRemoveSubitem,
  onAddSubitem,
  updateSyllabus,
}) => {
  {
    const [cards, setCards] = useState(initialState);

    useEffect(() => {
      if (initialState != initial) {
        setCards(initial)
      }
    }, [initial]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setCards((prevCards) => {
        let upt = update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        });
        // region notify
        let newIndex = 0;
        let updated = upt.map((item) => {
          return { ...item, id: newIndex++ };
        })
        onUpdateList(updated);
        // end region
        return upt
      }
      )
    }, [])

    const renderCard = (card, index) => {
      return (
        <>
          <Card
            key={card.id + '-' + Date.now()}
            index={index}
            id={card.id}
            text={card.name}
            moveCard={moveCard}
            onRemove={() => {
              onRemove(card);
            }}
            onEdit={() => {
              onEdit(card);
            }}
            onAddSubitem = {onAddSubitem}
          />
          {
          card.subItem?.length > 0 && 
          <DndProvider backend={HTML5Backend}>
            <DnDSubContainer
              itemId={card.id}
              initial={card.subItem}
              onUpdateList={onUpdateList} 
              onRemove={(card) => {
                // removeSyllabusElement(card.id);
              }}
              onEdit={(card) => {
                // onEditItem(card);
              }}
              onEditSubitem={onEditSubitem}
              onRemoveSubitem={onRemoveSubitem}
              onAddSubitem={onAddSubitem}
            />
          </DndProvider>
          }
        </>
      )
    }
    return (
        <div style={style}>{cards.map((card, i) => {
            return renderCard(card, i)
          })}</div>
    )
  }
}
