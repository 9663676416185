import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const TrainingNewForm1 = ({ courses, setCourse, formTrainingDataState }) => {
    return courses.length > 0 && (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={8}>
                    <Autocomplete
                        value={formTrainingDataState.course.name}
                        onInputChange={(event, newInputValue) => {
                            let course = courses.filter(course => course.name == newInputValue)
                            if (course.length > 0) {
                                setCourse(course[0])
                            }
                        }}
                        id="controllable-states-demo"
                        options={courses.map(course => course.name)}
                        renderInput={(params) => <TextField {...params} label="Curso" />}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default TrainingNewForm1;