import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const options = ['Adiministrador', 'Operador'];

export default function NewUserForm2({ formControl }) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Datos generales
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="Nombre"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            value={formControl.formDataState.name}
            onChange={( event ) => {
              formControl.setName(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Apellido"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            value={formControl.formDataState.lastname}
            onChange={( event ) => {
              formControl.setLastname(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Correo electrónico"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            value={formControl.formDataState.email}
            onChange={( event ) => {
              formControl.setEmail(event.target.value);
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
