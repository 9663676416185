import config from '../config/config';
import constants from '../utils/constants';

const APIUrl = `${config.apiHost}${config.apiBase}auth/login`


function isLoggedIn() {
    if (window.localStorage.getItem(constants.tokenKey) !== null) {
        return true;
    }
    return false; 
}

async function logout() {
    window.localStorage.removeItem(constants.tokenKey);
}

async function signIn(email, password) {
    const response = await fetch(APIUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'api': config.apiKey,
        },
        body: JSON.stringify({ email, password }),
    })
    .then((response) => {
        if(response.status != 200) {
            return null;
        }
        return response.json()
    })
    .then((data) => {
        if(!data) {
            return false;
        }
        window.localStorage.setItem(constants.tokenKey, data.token);
        return true;
    });

    return response;
}

export default { signIn, isLoggedIn, logout };