import React, { useReducer } from "react";
import UserService from "../../services/UserService";

const initialState = {
    name: "",
    lastname: "",
    email: "",
    type: "",
    isActive: false,
};

const Actions = {
    NAME: "NAME",
    LASTNAME: "LASTNAME",
    EMAIL: "EMAIL",
    TYPE: "TYPE",
    RESET: "RESET",
    IS_ACTIVE: "IS_ACTIVE",
}

const steps = ['Tipo de usuario', 'Detalles', 'Crear'];

const reducer = (state, payload) => {
    
    switch(payload.action) {
        case Actions.NAME : 
            return {
                ...state,
                name: payload.value,
            }
        case Actions.LASTNAME : 
            return {
                ...state,
                lastname: payload.value,
            }
        case Actions.EMAIL : 
            return {
                ...state,
                email: payload.value,
            }
        case Actions.TYPE : 
            return {
                ...state,
                type: payload.value,
            }
        case Actions.RESET :
                return initialState
        case Actions.IS_ACTIVE :
                return {
                    ...state,
                    isActive: payload.value,
                }
        default :
            return state;
    }
}

const useUserNew = () => {

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {

        // validate activeStep info 
        switch(activeStep) {
            case 0:
                if (formDataState.type === "") {
                    // invalid
                    return;
                }
                break;
            case 1 :
                if (formDataState.type === "" || formDataState.name === "" || formDataState.lastname == "") {
                    // invalid
                    return;
                }
                break;
        }
        if (activeStep === 0) {
            if (formDataState.type === "") {
                // invalid
                return;
            }
        }

        const nextStep = activeStep + 1;
        if (nextStep === steps.length) {
            const result = createUser();
            if (!result) return;
        }
        setActiveStep(nextStep);
    };

    const reset = () => {
        dispatch({ action: Actions.RESET });
        setActiveStep(0);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const [formDataState, dispatch] = useReducer(reducer, initialState);

    const setType = (type) => {
        dispatch({ action: Actions.TYPE, value: type });
    };

    const setName = (name) => {
        dispatch({ action: Actions.NAME, value: name });
    };

    const setLastname = (lastname) => {
        dispatch({ action: Actions.LASTNAME, value: lastname });
    };

    const setEmail = (email) => {
        dispatch({ action: Actions.EMAIL, value: email });
    };

    const setIsActive = (isActive) => {
        dispatch({ action: Actions.IS_ACTIVE, value: isActive });
    };

    const createUser = async () => {
        const response = await UserService.createUser(formDataState);
        return response;
    }

    return {
        // region form data
        formDataState,
        setType,
        setName,
        setLastname,
        setEmail,
        setIsActive,
        // region form flow
        activeStep,
        handleNext,
        handleBack,        
        createUser,
        steps,
        reset,
    };
};

export { useUserNew, Actions };