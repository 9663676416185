import React from 'react';

import { Grid, TextField } from "@mui/material";

const TrainingNewForm4 = ({ setCode, formTrainingDataState }) => {
    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={8}>
                    <TextField
                        id="outlined-basic"
                        label="Folio del curso"
                        variant="outlined"
                        value={formTrainingDataState.code}
                        onChange={event => {
                            setCode(event.target.value)
                        }} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default TrainingNewForm4;