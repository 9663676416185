import { useEffect, useReducer, useState } from "react";
import StudentService from "../../services/StudentService";
import CompanyService from "../../services/CompanyService";

var initialData = {
    id: "", 
    name: "",
    lastname: "",
    curp: null, 
    rfc: null, 
    trainings: [],
    company: null,
};

function responseToStudentDetail(response) {
    return {
        id: response.id, 
        name: response.name,
        lastname: response.lastname,
        curp: response.curp, 
        rfc: response.rfc,
        trainings: createTraining(response.trainings),
        company: response.company,
    }
}

function createTraining(trainings) {
    const some = trainings.map((training) => {
        const newTraining = {
            ...training,
            dateStart: new Date(training.dateStart),
            dateEnd: new Date(training.dateEnd),
        };
        return newTraining;
    });
    return some;
}

const ACTION_INITIAL = "ACTION_INITIAL";
const ACTION_UPDATE_NAME = "ACTION_UPDATE_NAME"
const ACTION_UPDATE_LASTNAME = "ACTION_UPDATE_LASTNAME"
const ACTION_UPDATE_RFC = "ACTION_UPDATE_RFC"
const ACTION_UPDATE_CURP = "ACTION_UPDATE_CURP"
const ACTION_UPDATE_COMPANY = "ACTION_UPDATE_COMPANY"

const reducer = (state, payload) => {
    switch(payload.action) {
        case ACTION_INITIAL :
            initialData = payload.data
            return payload.data;
        case ACTION_UPDATE_NAME :
            return {
                ...state,
                name: payload.data,
            }
        case ACTION_UPDATE_LASTNAME :
                return {
                    ...state,
                    lastname: payload.data,
                }
        case ACTION_UPDATE_RFC : 
            return {
                ...state,
                rfc: payload.data,
            }
        case ACTION_UPDATE_CURP :
            return {
                ...state,
                curp: payload.data,
            }
        case ACTION_UPDATE_COMPANY :
            return {
                ...state,
                company: payload.data,
            }
        default:
            return state;
    }
}

const useStudentDetail = (studentId) => {

    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateFailure, setUpdateFailure] = useState(false);
    const [originalDataChanged, setOriginalDataChanged] = useState(false);
    const [companies, setCompanies] = useState([]);

    const [ studentDetails, dispatch ] = useReducer(reducer, initialData);
 
    const getStudent = async () => {
        const response = await StudentService.getStudent(studentId)
        if (response) {
            setInitialData(responseToStudentDetail(response));
        }
    };

    useEffect(() => {
        originalDataHasChanged();
    }, [studentDetails]);

    const getCompanies = async () => {
        const response = await CompanyService.getCompanies();
        if (response) {
            setCompanies(response)
        }
    };

    const originalDataHasChanged = () => {
        const hasChanged = JSON.stringify(studentDetails) !== JSON.stringify(initialData);
        setOriginalDataChanged(hasChanged);
    };

    const setInitialData = (data) => {
        dispatch({ action: ACTION_INITIAL, data: data });
    }
    
    const updateName = (name) => {
        dispatch({ action: ACTION_UPDATE_NAME, data: name });
    }

    const updateLastname = (lastname) => {
        dispatch({ action: ACTION_UPDATE_LASTNAME, data: lastname });
    }

    const updateRFC = (rfc) => {
        dispatch({ action: ACTION_UPDATE_RFC, data: rfc });
    }

    const updateCURP = (curp) => {
        dispatch({ action: ACTION_UPDATE_CURP, data: curp });
    }

    const updateCompany = (company) => {
        dispatch({ action: ACTION_UPDATE_COMPANY, data: company });
    }

    const handleClick = () => {
        setUpdateSuccess(true);
        setUpdateFailure(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateSuccess(false);
        setUpdateFailure(false);
    };

    const updateStudent = async () => {
        const response = await StudentService.updateStudent(studentDetails.id, studentDetails);
        if (response) {
            setInitialData(responseToStudentDetail(response));
            setUpdateSuccess(true);
        } else {
            setUpdateFailure(true);
        }
    };

    return {
        studentDetails,
        getStudent,
        updateSuccess,
        updateFailure,
        handleClick,
        handleClose,
        originalDataChanged,
        updateCURP,
        updateName,
        updateLastname,
        updateRFC,
        updateStudent,
        updateCompany,
        companies,
        getCompanies,
    }
};

export { useStudentDetail };