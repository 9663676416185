import { useState } from "react"
import MainContainer from "../../components/dashboard/MainContainer";
import UserDetail from "../../components/userNew/UserNew";
import Users from "../../components/users/Users";
import LoginService from "../../services/LoginService"

const useDashboard = () => {

    const [loginState, setLoginState] = useState(LoginService.isLoggedIn());

    const logout = () => {
        LoginService.logout();
        setLoginState(LoginService.isLoggedIn());
    };


    return {
        loginState,
        logout,
    }
}

export { useDashboard }