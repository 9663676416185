import config from "../config/config"
import { callService } from "./CommonService";

const APIUrlBase = config.apiHost + config.apiBase + 'courses';

/* Retrieve list of all courses in the system */
async function getCourses() {
    const url = APIUrlBase;
    return await callService(url);
}

/* Retrieve one single course data */
async function getCourse(courseId) {
    const url = APIUrlBase + '/' + courseId;
    return await callService(url);
}

/* Delete course */
async function deleteCourse(courseId) {
    const url = APIUrlBase + '/' + courseId;
    const method = 'DELETE';
    return await callService(url, method);
}

/* Call API service to create a user */
async function updateCourse(courseId, data) {
    const url = APIUrlBase + '/' + courseId;
    const method = 'PATCH';
    const payload = {
        name: data.name,
        sptr: data.sptr !== '' ? data.sptr : null,
        syllabus: data.syllabus,
    };
    return await callService(url, method, payload);
}

/* Call API service to create a course */
async function createCourse(data) {
    const url = APIUrlBase;
    const method = 'POST';
    const payload = {
        name: data.name,
        sptr: data.sptr !== '' ? data.sptr : null,
        syllabus: data.syllabus,
    };
    return await callService(url, method, payload);
}

export default { getCourses, getCourse, updateCourse, createCourse, deleteCourse };