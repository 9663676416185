import { useEffect, useReducer, useState } from "react";
import InstructorService from "../../services/InstructorService";

var initialData = {
    id: "",
    name: "",
    lastname: "",
    instructorSTPS: "",
    spptr: "",
};

const ACTION_INITIAL = "ACTION_INITIAL";
const ACTION_UPDATE_NAME = "ACTION_UPDATE_NAME"
const ACTION_UPDATE_LASTNAME = "ACTION_UPDATE_LASTNAME"
const ACTION_UPDATE_INSTRUCTOR_STPS = "ACTION_UPDATE_INSTRUCTOR_STPS"
const ACTION_UPDATE_INSTRUCTOR_SPPTR = "ACTION_UPDATE_INSTRUCTOR_SPPTR"

const reducer = (state, payload) => {
    switch (payload.action) {
        case ACTION_INITIAL:
            initialData = payload.data
            return payload.data;
        case ACTION_UPDATE_NAME:
            return {
                ...state,
                name: payload.data,
            }
        case ACTION_UPDATE_LASTNAME:
            return {
                ...state,
                lastname: payload.data,
            }
        case ACTION_UPDATE_INSTRUCTOR_STPS:
            return {
                ...state,
                instructorSTPS: payload.data,
            }
        case ACTION_UPDATE_INSTRUCTOR_SPPTR:
            return {
                ...state,
                spptr: payload.data,
            }
        default:
            return state;
    }
}

const useInstructorNew = () => {

    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateFailure, setUpdateFailure] = useState(false);
    const [originalDataChanged, setOriginalDataChanged] = useState(false);
    const [redirect, setRedirect] = useState(null);

    const [instructorDetails, dispatch] = useReducer(reducer, initialData);

    useEffect(() => {
        originalDataHasChanged();
    }, [instructorDetails]);

    const originalDataHasChanged = () => {

        const hasChanged = instructorDetails.name !== ""
            && instructorDetails.lastname !== ""

        setOriginalDataChanged(hasChanged);
    };

    const setInitialData = (data) => {
        dispatch({ action: ACTION_INITIAL, data: data });
    }

    const updateName = (name) => {
        dispatch({ action: ACTION_UPDATE_NAME, data: name });
    }

    const updateLastname = (lastname) => {
        dispatch({ action: ACTION_UPDATE_LASTNAME, data: lastname });
    }

    const updateInstructorSTPS = (instructorSTPS) => {
        dispatch({ action: ACTION_UPDATE_INSTRUCTOR_STPS, data: instructorSTPS });
    };

    const updateSPPTR = (spptr) => {
        dispatch({ action: ACTION_UPDATE_INSTRUCTOR_SPPTR, data: spptr });
    };

    const handleClick = () => {
        setUpdateSuccess(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateSuccess(false);
        setUpdateFailure(false);
    };

    const createInstructor = async () => {
        const response = await InstructorService.createInstructor(instructorDetails);
        if (response) {
            setInitialData(initialData);
            setRedirect(`/admin/instructors/${response.id}`);
            setUpdateSuccess(true);
        } else {
            setUpdateFailure(true);
        }
    };

    return {
        instructorDetails,
        updateSuccess,
        updateFailure,
        handleClick,
        handleClose,
        originalDataChanged,
        updateName,
        updateLastname,
        updateInstructorSTPS,
        updateSPPTR,
        createInstructor,
        redirect,
    }
};

export { useInstructorNew };