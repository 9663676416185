import { useState, useReducer, useEffect } from "react";
import DeputyService from "../../services/DeputyService";

function createData(response) {
    return {
        id: response.id,
        name: response.name,
        lastname: response.lastname,
        position: response.position,
        signature: response.signature,
    };
}

const initialDeputyDetail = {
    id: "",
    name: "",
    lastname: "",
    completeName: "",
    position: "",
    signature: null,
}

var initialData = null;

const ACTION_INITIAL = "ACTION_INITIAL";
const ACTION_UPDATE_NAME = "ACTION_UPDATE_NAME";
const ACTION_UPDATE_LASTNAME = "ACTION_UPDATE_LASTNAME";
const ACTION_UPDATE_POSITION = "ACTION_UPDATE_POSITION";
const ACTION_UPDATE_SIGNATURE = "ACTION_UPDATE_SIGNATURE";

const reducer = (state, payload) => {
    switch (payload.action) {
        case ACTION_INITIAL:
            initialData = payload.data
            return payload.data;
        case ACTION_UPDATE_NAME:
            return {
                ...state,
                name: payload.data,
            }
        case ACTION_UPDATE_LASTNAME:
            return {
                ...state,
                lastname: payload.data,
            }
        case ACTION_UPDATE_POSITION:
            return {
                ...state,
                position: payload.data,
            }
        case ACTION_UPDATE_SIGNATURE:
            return {
                ...state,
                signature: payload.data,
            }
        default:
            return state;
    }
}

const useDeputies = () => {

    const [deputies, setDeputies] = useState([]);
    const [deputyDetail, dispatch] = useReducer(reducer, initialDeputyDetail);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateFailure, setUpdateFailure] = useState(false);
    const [originalDataChanged, setOriginalDataChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        checkDataChanged();
        getSignature();
    }, [deputyDetail]);

    const checkDataChanged = () => {
        const hasChanged = JSON.stringify(initialData) !== JSON.stringify(deputyDetail);
        setOriginalDataChanged(hasChanged);
    }

    const getDeputies = async () => {
        const response = await DeputyService.getDeputies();
        const deputiesParsed = response.map((instructor) => createData(instructor));
        setDeputies(deputiesParsed);
        setIsLoading(false);
    }

    const handleClick = () => {
        setUpdateSuccess(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setUpdateSuccess(false);
    };

    const getDeputyDetails = async (deputyId) => {
        const response = await DeputyService.getDeputy(deputyId);
        if (response) {
            const deputy = createData(response);
            dispatch({ action: ACTION_INITIAL, data: deputy });
        }
    }

    const updateName = (name) => {
        dispatch({ action: ACTION_UPDATE_NAME, data: name });
    }

    const updateLastname = (lastname) => {
        dispatch({ action: ACTION_UPDATE_LASTNAME, data: lastname });
    }

    const updatePosition = (position) => {
        dispatch({ action: ACTION_UPDATE_POSITION, data: position });
    };

    const updateDeputySignature = (signature) => {
        dispatch({ action: ACTION_UPDATE_SIGNATURE, data: signature });
    };

    const updateDeputy = async () => {
        const response = await DeputyService.updateDeputy(deputyDetail.id, deputyDetail);
        if (response) {
            dispatch({ action: ACTION_INITIAL, data: response });
            setUpdateSuccess(true);
        } else {
            setUpdateFailure(true);
        }
    };

    const createDeputy = async () => {
        const response = await DeputyService.createDeputy(deputyDetail);
        if (response) {
            setUpdateSuccess(true);
        } else {
            setUpdateFailure(true);
        }
    }

    const updateSignature = async (signatureFile) => {
        const response = await DeputyService.updateDeputySignature(deputyDetail.id, signatureFile);
        if (response) {
            updateDeputySignature(signatureFile.name);
        } else {
            setUpdateFailure(true);
        }
    };

    const getSignature = async () => {
        if (deputyDetail.id) {
            const response = await DeputyService.getDeputySignature(deputyDetail.id);
            if (response) {
                var reader = new window.FileReader();
                reader.readAsDataURL(response);
                reader.onload = function () {
                    setImageUrl(reader.result)
                }
            } else {
                setImageUrl(null)
            }
        } else {
            setImageUrl(null)
        }
    };

    const [imageUrl, setImageUrl] = useState(null);

    const handleOnChangeSignature = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            updateSignature(file);
            setImageUrl(reader.result);
        };

        reader.readAsDataURL(file);
    }

    return {
        deputies,
        getDeputies,
        deputyDetail,
        getDeputyDetails,
        handleClick,
        handleClose,
        originalDataChanged,
        updateSuccess,
        updateFailure,
        updateName,
        updateLastname,
        updatePosition,
        updateDeputy,
        createDeputy,
        handleOnChangeSignature,
        imageUrl,
        isLoading,
    }
};

export default useDeputies;