import config from "../config/config"
import { callService } from "./CommonService";

const APIUrlBase = config.apiHost + config.apiBase + 'companies';

/* Retrieve list of all of guest in the system */
async function getCompanies() {
    const url = APIUrlBase;
    return await callService(url);
}

/* Retrieve one single user data */
async function getCompany(courseId) {
    const url = APIUrlBase + '/' + courseId;
    return await callService(url);
}

/* Call API service to create a user */
async function updateCompany(courseId, data) {
    const url = APIUrlBase + '/' + courseId;
    const method = 'PATCH';
    const payload = {
        name: data.name,
    };
    return await callService(url, method, payload);
}

/* Call API service to create a user */
async function createCompany(data) {
    const url = APIUrlBase;
    const method = 'POST';
    const payload = {
        name: data.name,
    };
    return await callService(url, method, payload);
}

export default { getCompanies, getCompany, updateCompany, createCompany };