import { useState } from "react";

import UserService from "../../services/UserService";

function createData(id, name, email, rol, isActive) {
    return { id, name, email, rol, isActive };
}

const useUsers = () => {
    const [rows, setRows] = useState([]);

    const getUsers = async () => {
        const response =  await UserService.getUsers();
        const users = response.map((user) => createData(user.id, user.name, user.email, user.role.name, user.isActive));
        setRows(users);
    }
    
    return {
        rows,
        getUsers,
    }
};

export { useUsers };