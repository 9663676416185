import config from "../config/config"
import { callService, getBase64, uploadFile } from "./CommonService";

const APIUrlBase = config.apiHost + config.apiBase + 'deputies';

/* Retrieve list of all of guest in the system */
async function getDeputies() {
    const url = APIUrlBase;
    return await callService(url);
}

/* Retrieve one single user data */
async function getDeputy(deputyId) {
    const url = APIUrlBase + '/' + deputyId;
    return await callService(url);
}

/* Call API service to create a user */
async function createDeputy(data) {

    const url = APIUrlBase;
    const method = "POST";
    const payload = {
        name: data.name,
        lastname: data.lastname,
        position: data.position,
    };

    return await callService(url, method, payload);
}

/* Call API service to create a user */
async function updateDeputy(deputyId, data) {

    const url = APIUrlBase + '/' + deputyId;
    const method = 'PATCH';
    const payload = {
        name: data.name,
        lastname: data.lastname,
        position: data.position,
    };

    return await callService(url, method, payload);
}

async function updateDeputySignature(instructorId, signatureFile) {
    return await uploadFile(APIUrlBase + '/' + instructorId + '/upload-signature', signatureFile)
}

async function getDeputySignature(instructorId) {
    return await getBase64(APIUrlBase + '/' + instructorId + '/signature');
}

export default { getDeputies, getDeputy, createDeputy, updateDeputy, updateDeputySignature, getDeputySignature };