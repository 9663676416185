import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes.js'
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

const style = {
  display: 'flex',
  justifyContent: 'space-between',
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
}

const processSubitems = (id, subitems, onEdit, onRemove) => {
  return subitems?.map((subitem) => {
    return (<div key={id + '-' + subitem.id} style={{...style, marginLeft: '2em', paddingLeft: '1em'}}>
    {subitem.name}
    <div style={{width:'65px', display: 'flex', justifyContent: 'space-between',}}>
      <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          onClick={() => onEdit(id, subitem)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          onClick={() => { onRemove(id, subitem.id) }}
        >
          <CloseIcon />
        </IconButton>
    </div>
  </div>)
  });
}
export const Card = ({ 
  id, 
  text, 
  index, 
  moveCard, 
  onRemove, 
  onEdit, 
  onAddSubitem,
}) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      const isSubItem = clientOffset.x - 400 > 60;
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, isSubItem)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (<div key={id}>
    <div key={id} ref={ref} style={{ ...style, opacity }}>
      <div data-handler-id={handlerId}>
        {text}
      </div>
      <div style={{width:'85px', display: 'flex', justifyContent: 'space-between',}}>
      <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          onClick={() => onAddSubitem(id) }
        >
          <AddIcon />
        </IconButton>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          onClick={onEdit}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="close"
          onClick={onRemove}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
    { 
    /* processSubitems(
      id,
      subitems,
      onEditSubitem,
      onRemoveSubitem
    ) */
    }
    </div>
  )
}
