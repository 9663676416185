import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CertificateConstants } from '../../helper/CertificateHelper';
import { AppBar, Toolbar, Typography, Button, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { PDFDownloadLink, PDFViewer, Text, pdf } from '@react-pdf/renderer';
import Certificate1 from './version1/Certificate1';
import Certificate2 from './version2/Certificate2';
import useDownload from '../../hooks/DownloadHook';
import InstructorService from '../../services/InstructorService';
import DeputyService from '../../services/DeputyService';
import { useEffect } from 'react';


const signatureFileToString = (file) => {
    const promise = new Promise((resolve, reject) => {
      var reader = new window.FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      }
    });
  
    return promise;
  }

export function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const { handleZip }  = useDownload(); 

    const [downloadZip, setDownloadZip] = React.useState(false);

    const [hasSignature, setHasSignature] = React.useState(true);

    const filename = props.trainingDetail.code.replace(CertificateConstants.STUDENT_ID_REPLACE_PATTERN, "");

    const [version, setVersion] = React.useState(1);

    const [instructorSignature, setInstructorSignature] = React.useState(null);
    const [deputySignature, setDeputySignature] = React.useState(null);

    const fetchData = async () => {
        // get the signature for Instructors
        if (props && props.trainingDetail) {
            const responseInstructorSignature = await InstructorService.getInstructorSignature(props.trainingDetail.instructor.id);
            const responseDeputySignature = await DeputyService.getDeputySignature(props.trainingDetail.deputy.id);
            if (responseInstructorSignature) {
                const signatureString = await signatureFileToString(responseInstructorSignature);
                setInstructorSignature(signatureString);
            }
            if (responseDeputySignature) {
                const signatureString = await signatureFileToString(responseDeputySignature);
                setDeputySignature(signatureString);
            } 
        }
    };

    useEffect(() => {
        fetchData();
    }, [props.trainingDetail]);
    
    const handleVersionChange = (e) => {
        const v = e.target.value;
        setVersion(v);
    };

    const getCertificate = (version, trainingDetail, isPreview, hasSignature, instructorSignature, deputySignature) => {
        if (version == 1) {
            return <Certificate1 key={version + Date.now()} trainingDetail={trainingDetail} isPreview={isPreview} signature={hasSignature} instructorSignature={instructorSignature} deputySignature={deputySignature} />;
        } else {
            return <Certificate2 key={version + Date.now()} trainingDetail={trainingDetail} isPreview={isPreview} signature={hasSignature} instructorSignature={instructorSignature} deputySignature={deputySignature} />;
        }
    } 
    
    const getDownloadLink = (version, trainingDetail, filename, hasSignature ) => {
        return (<PDFDownloadLink key={version + Date.now()} document={getCertificate(version, trainingDetail, false, hasSignature, instructorSignature, deputySignature)} fileName={`${filename}.pdf`}>
            {({ blob, url, loading, error }) => {
                return (loading 
                    ? 'Cargando documento...' 
                    : <Button autoFocus style={{ color: 'white'}} color="inherit">
                        Descargar
                    </Button>)
            }}
            </PDFDownloadLink>);
    }

    const PDFRender = ({ trainingDetail, version, hasSignature, instructorSignature, deputySignature }) => (
        <PDFViewer height={'100%'}>
            {trainingDetail &&  getCertificate(version, trainingDetail, true, hasSignature, instructorSignature, deputySignature) }
        </PDFViewer>
    );

    return (
        <Dialog
            fullScreen
            onClose={handleClose}
            open={open}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Certificado
                    </Typography>
                    <FormControl>
                        <Select 
                        style={{ color: 'white'}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={version}
                        label="Version"
                        onChange={handleVersionChange}
                        >
                            <MenuItem value={1}>Version 1 (STPS)</MenuItem>
                            <MenuItem value={2}>Version 2 (SPPTR)</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel 
                        control={<Checkbox color="default" />}
                        label="Constancia por archivo"
                        onChange={(d)  => { 
                            setDownloadZip(d.target.checked);
                        }}>
                    </FormControlLabel>
                    <FormControlLabel 
                        control={<Checkbox color="default" checked={hasSignature}/>}
                        label="Firma"
                        onChange={(d)  => { 
                            setHasSignature(d.target.checked);
                        }}></FormControlLabel>
                    { downloadZip 
                        ? <Button autoFocus style={{ color: 'white'}} color="inherit" onClick={ (e) => {
                                e.preventDefault();
                                // Create zip handler
                                const blobs = new Array();
                                for(let i = 0; i < props.trainingDetail.students.length; i++ ) {
                                    const studentData = props.trainingDetail.students[i];
                                    const data = { 
                                        ...props.trainingDetail, 
                                        students: [studentData],
                                    };
                                    const blob = {
                                        filename: props.trainingDetail.code.replace(CertificateConstants.STUDENT_ID_REPLACE_PATTERN, studentData.id.toString().padStart(4, "0")),
                                        data: pdf(getCertificate(version, data, false, hasSignature, instructorSignature, deputySignature)).toBlob()
                                    };
                                    blobs.push(blob);
                                }
                                handleZip(filename, blobs);
                            }}>
                            Descargar
                        </Button>
                    : getDownloadLink(version, props.trainingDetail, filename, hasSignature)
                        
                }
                </Toolbar>
            </AppBar>
            <PDFRender 
                trainingDetail={props.trainingDetail} 
                version={version} 
                hasSignature={hasSignature} 
                instructorSignature={instructorSignature} 
                deputySignature={deputySignature}
            />
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    trainingDetail: PropTypes.object.isRequired,
};
