import React from 'react';
import UserNameContext from '../../contexts/UserNameContext';
import Title from '../dashboard/Title';
import { Link } from 'react-router-dom';
import { DataGrid, esES } from '@mui/x-data-grid';
import * as locales from '@mui/material/locale';
import { Typography, createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';

const theme = createTheme({}, esES);

function makeDetailLink(name, id) {
  return (<Link to={`${id}`}>{`${name}`}</Link>);
}

function createHeaderItem( field, headerName, width, renderCell, type ) {

  const item = {
    field,
    headerName, 
    width,
    renderCell,
    type: type ?? 'string',
  }
  return item;
}

const DeputiesDataGrid = ({headers, rows}) => {

  const onRowClick = (event) => {
    console.log(event);
  }

  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales['esES']),
    [locales, theme],
  );

  return (
    <ThemeProvider theme={themeWithLocale}>
      <DataGrid
        onRowClick={onRowClick}
        rows={rows}
        disableRowSelectionOnClick={false}
        columns={headers.map((headerItem, _) => headerItem)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 8 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection={false}
      />
      </ThemeProvider>
    
  );
};

export default function DeputiesTable({
  title,
  headers,
  rows,
}) {

  const value = React.useContext(UserNameContext);

  return (
    <React.Fragment>
      <Title>{title}</Title>
      {rows.length == 0
        ? <Typography>No se han registrado representates aún</Typography>
        : <DeputiesDataGrid headers={
          [
            createHeaderItem('name', 'Nombre', 270, (params) => {
              return makeDetailLink(params.row.name, params.row.id);
            }), 
            createHeaderItem('lastname', 'Apellido', 270), 
            createHeaderItem('position', 'Posición', 300), 
          ]
        } 
        rows={rows}/>
      }
    </React.Fragment>
  );
}
