import React from 'react';
import UserNameContext from '../../contexts/UserNameContext';
import Title from '../dashboard/Title';
import { Link } from 'react-router-dom';
import { DataGrid, esES } from '@mui/x-data-grid';
import { Typography, createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import * as locales from '@mui/material/locale';
import { dayInMonthComparator } from '../../utils/dateUtils';

export function makeDetailLink(name, id) {
  return (<Link to={`${id}`}>{`${name}`}</Link>);
}

const theme = createTheme({}, esES);

function createHeaderItem( field, headerName, width, renderCell, type ) {

  const item = {
    field,
    headerName, 
    width,
    renderCell,
    type: type ?? 'string',
  }

  if (type == 'date') {
    item['sortComparator'] = dayInMonthComparator; 
  }

  return item;
}

const TrainingsDataGrid = ({headers, rows}) => {

  const onRowClick = (event) => {
    console.log(event);

  }
  return (
    <DataGrid
      onRowClick={onRowClick}
      rows={rows}
      disableRowSelectionOnClick={false}
      columns={headers.map((headerItem, _) => headerItem)}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 8 },
        },
      }}
      pageSizeOptions={[5, 10]}
      checkboxSelection={false}
    />
  );
};

export default function TrainingsTable({
  title,
  rows,
}) {

  const value = React.useContext(UserNameContext);

  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales['esES']),
    [locales, theme],
  );

  return (
    <React.Fragment>
      <ThemeProvider theme={themeWithLocale}>
        <Title>{title}</Title>
        {
          rows.length == 0
          ? <Typography>No se han registrado capacitaciones aún</Typography>
          : <TrainingsDataGrid headers={
              [
                createHeaderItem('trainingName', 'Nombre', 500, (params) => {
                  return makeDetailLink(params.row.trainingName, params.row.id);
                }), 
                createHeaderItem('dateStart', 'Inicia', 200, (params) => {
                  return params.row.dateStart.toLocaleDateString('es-ES', {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });
                }, 'date'), 
                createHeaderItem('dateEnd', 'Fin', 200, (params) => {
                  return params.row.dateEnd.toLocaleDateString(
                    'es-ES', {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  );
                }, 'date'), 
                createHeaderItem('code', 'Código', 170), 
              ]
            } 
            rows={rows}/>
        }
      </ThemeProvider>
    </React.Fragment>
  );
}
