import config from "../config/config"
import { callService } from "./CommonService";

const APIUrlBase = config.apiHost + config.apiBase + 'logs'; 

/* Retrieve list of all of guest in the system */
async function getLogs() {
    const url = APIUrlBase;
    return await callService(url);
}

export default { getLogs };
