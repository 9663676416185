import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import useTrainings, { FlowType } from './Trainings.services';
import TrainingNewForm1 from './TrainingNewForm1';
import TrainingNewForm2 from './TrainingNewForm2';
import TrainingNewForm3 from './TrainingNewForm3';
import TrainingNewForm4 from './TrainingNewForm4';
import TrainingNewForm5 from './TrainingNewForm5';
import { Link } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function getStepContent(
    step,
    formTrainingDataState,
    courses,
    setCourse,
    instructors,
    setInstructor,
    setDateStart,
    setDateEnd,
    setCode,
    students,
    addStudent,
    removeStudents,
    companies,
    byCompany,
    setFilterByCompany,
    selectedStudents,
    checkStudent,
    addStudentBulk,
    removeStudentBulk,
) {
    switch (step) {
        case 0:
            return <TrainingNewForm1
                courses={courses}
                setCourse={setCourse}
                formTrainingDataState={formTrainingDataState}
            />;
        case 1:
            return <TrainingNewForm2
                setDateStart={setDateStart}
                setDateEnd={setDateEnd}
                formTrainingDataState={formTrainingDataState} />;
        case 2:
            return <TrainingNewForm3
                instructors={instructors}
                setInstructor={setInstructor}
                formTrainingDataState={formTrainingDataState} />;
        case 3:
            return <TrainingNewForm4
                setCode={setCode}
                formTrainingDataState={formTrainingDataState} />;
        case 4:
            return <TrainingNewForm5
                students={students}
                addStudent={addStudent}
                removeStudents={removeStudents}
                formTrainingDataState={formTrainingDataState}
                companies={companies}
                byCompany={byCompany}
                setFilterByCompany={setFilterByCompany}
                selectedStudents={selectedStudents}
                checkStudent={checkStudent}
                addStudentBulk={addStudentBulk}
                removeStudentBulk={removeStudentBulk}
                />;
        default:
            throw new Error('Unknown step');
    }
}

const theme = createTheme();

const TrainingNew = () => {
    const {
        steps,
        activeStep,
        handleBack,
        handleNext,
        courses,
        setCourse,
        formTrainingDataState,
        instructors,
        setInstructor,
        setDateStart,
        setDateEnd,
        setCode,
        students,
        addStudent,
        removeStudents,
        handleClose,
        updateSuccess,
        updateFailure,
        redirect,
        companies,
        byCompany,
        setFilterByCompany,
        selectedStudents,
        checkStudent,
        addStudentBulk,
        removeStudentBulk,
    } = useTrainings(FlowType.create);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        <Typography component="h1" variant="h4" align="center">
                            Nueva Capacitación
                        </Typography>
                        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                    Capacitación creada exitosamente.
                                </Typography>
                                <Typography variant="subtitle1">
                                    La confirmación ha sido enviada al correo electrónico del nuevo usuario.
                                </Typography>
                                <Button
                                    variant="outlined"
                                    sx={{ mt: 3, ml: 1 }}
                                    onClick={() => { } /* reset() */}
                                >
                                    Añadir otra capacitación
                                </Button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {
                                    getStepContent(
                                        activeStep,
                                        formTrainingDataState,
                                        courses,
                                        setCourse,
                                        instructors,
                                        setInstructor,
                                        setDateStart,
                                        setDateEnd,
                                        setCode,
                                        students,
                                        addStudent,
                                        removeStudents,
                                        companies,
                                        byCompany,
                                        setFilterByCompany,
                                        selectedStudents,
                                        checkStudent,
                                        addStudentBulk,
                                        removeStudentBulk,
                                    )
                                }
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                            Atrás
                                        </Button>
                                    )}

                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 3, ml: 1 }}
                                    >
                                        {activeStep === steps.length - 1 ? 'Crear' : 'Siguiente'}
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )}
                    </Paper>
                </Container>
                {redirect && <Snackbar open={updateSuccess} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Capacitación creada <Link to={redirect} style={{color: 'white'}}>Ver detalle</Link>
                    </Alert>
                </Snackbar>}
                <Snackbar open={updateFailure} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        Error, no se pudo crear la capacitación
                    </Alert>
                </Snackbar>
            </ThemeProvider>
        </Box>
    );
};

export default TrainingNew;
